import React, { useState } from 'react';
import '../css/ContactForm.css';
import { submitLeadForm } from '../services/api';
import { validateForm } from '../utils/validation';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobileNo: '',
        email: '',
        configuration: ''
    });
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setSuccess(false);
        
        // Validate form
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        
        try {
            await submitLeadForm(formData);
            setSuccess(true);
            // Clear form after successful submission
            setFormData({
                name: '',
                email: '',
                mobileNo: '',
                configuration: ''
            });
        } catch (error) {
            console.error('Form submission error:', error);
            setErrors({ submit: 'Failed to submit form. Please try again.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    return (
        <div className="contact-form-container">
            <h2>Get in touch with us</h2>
            {success && (
                <div className="success-message">
                    Thank you for your submission! We'll contact you soon.
                </div>
            )}
            {errors.submit && (
                <div className="error-message">{errors.submit}</div>
            )}
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name*"
                        value={formData.name}
                        onChange={handleChange}
                        className={errors.name ? 'error' : ''}
                        required
                    />
                    {errors.name && <span className="error-text">{errors.name}</span>}
                </div>

                <div className="form-group">
                    <input 
                        type="tel"
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        className={errors.mobileNo ? 'error' : ''}
                        required
                    />
                    {errors.mobileNo && <span className="error-text">{errors.mobileNo}</span>}
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Your e-mail*"
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? 'error' : ''}
                        required
                    />
                    {errors.email && <span className="error-text">{errors.email}</span>}
                </div>

                <div className="form-group">
                    <select
                        name="configuration"
                        value={formData.configuration}
                        onChange={handleChange}
                        className={errors.configuration ? 'error' : ''}
                    >
                        <option value="">Select Property Type</option>
                        <option value="2 BHK">2 BHK</option>
                        <option value="3 BHK">3 BHK</option>
                    </select>
                    {errors.configuration && <span className="error-text">{errors.configuration}</span>}
                </div>

                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
