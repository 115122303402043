import React, { useState } from 'react';
import '../css/About.css';

const About = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleViewMore = (e) => {
        e.preventDefault();
        setShowMore(!showMore);
    };

    return (
        <div className="about-container">
            <div className="about-content">
                <h2 className="about-title">
                    <span>About </span>
                    <span>ROCHISHMATI</span>
                </h2>
                <div className="about-description">
                    <p>The origin of Rochishmati Infra Projects (P) Ltd. has a strong thinking process in the back drop to provide Quality and affordable homes to the people from various sectors.</p>
                    <a href='#about-rochishmati' className='view-more' onClick={toggleViewMore}>
                        {showMore ? 'view less' : 'view more'}
                    </a>
                    <div className={`about-rochishmati ${showMore ? 'show' : ''}`}>
                        <p>The Company has a strong team of people from various segments and sectors with varied experience. The Company is run by professional who worked with various MNC's, all across the world.</p>
                        <p>The Company is in good hands of professionals, NRI's, Engineers with an experience of more than 15 Years in the Field of Infrastructure. The company is well supported by an Architect with diversified experience in providing designs for Gated Communities, Villas, Malls, Apartments Etc. The association of a Chartered Account with The Company handles The Finance and Accounting Part. The Company is well supported by Doctors from India and Abroad.</p>
                    </div> 
                </div>
            </div>
            <div className="about-image">
                {/* Image placeholder */}
                <img src={require('../assets/images/1.webp')} alt='about' className='img-fluid'/>
            </div>
        </div>
    );
};

export default About;