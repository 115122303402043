export const validateForm = (formData) => {
    const errors = {};

    // Name validation
    if (!formData.name.trim()) {
        errors.name = 'Name is required';
    } else if (formData.name.trim().length < 3) {
        errors.name = 'Name must be at least 3 characters';
    }

    // Mobile number validation
    if (!formData.mobileNo) {
        errors.mobileNo = 'Mobile number is required';
    } else if (!/^[6-9]\d{9}$/.test(formData.mobileNo)) {
        errors.mobileNo = 'Please enter a valid 10-digit mobile number';
    }

    // Email validation
    if (!formData.email) {
        errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = 'Please enter a valid email address';
    }

    // Configuration validation
    if (!formData.configuration) {
        errors.configuration = 'Please select a configuration';
    }

    return errors;
};
