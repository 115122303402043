import React from 'react';
import HeroSection from '../components/HeroSection';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Location from '../components/Location';
import Amenities from '../components/Amenities';
import Plans from '../components/Plans';
import ContactUs from '../components/ContactUs';

const Home = () => {
  return (
    <div className="App">
      <div id="home">
        <HeroSection />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="gallery">
        <Gallery />
      </div>
      <div id="location">
        <Location />
      </div>
      <div id="amenities">
        <Amenities />
      </div>
      <Plans />
      <div style={{backgroundColor: '#1B332C', marginTop:'20px'}} id='contact'>
        <ContactUs />
      </div>
    </div>
  );
}

export default Home;
