import React, { useState } from 'react';
import '../css/Gallery.css';

const Gallery = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);
    
    const slides = [
        { id: 1, image: require('../assets/images/1.webp') },
        { id: 2, image: require('../assets/images/2.webp') },
        { id: 3, image: require('../assets/images/3.webp') },
        { id: 4, image: require('../assets/images/4.webp') },
        { id: 5, image: require('../assets/images/5.webp') },
        // { id: 6, image: require('../assets/images/6.webp') },
    ];

    // Auto-play effect
    React.useEffect(() => {
        if (!isAutoPlaying) return;
        
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [isAutoPlaying, slides.length]);

    // Modified navigation functions to temporarily pause auto-play
    const nextSlide = () => {
        setIsAutoPlaying(false);
        setCurrentSlide((prev) => (prev + 1) % slides.length);
        // Resume auto-play after a short delay
        setTimeout(() => setIsAutoPlaying(true), 2000);
    };

    const prevSlide = () => {
        setIsAutoPlaying(false);
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
        // Resume auto-play after a short delay
        setTimeout(() => setIsAutoPlaying(true), 2000);
    };

    return (
        <div className="gallery-container">
            <h1 className="gallery-title">
                <span className="title-gr">OUR</span>
                <span className="title-gallery">Gallery</span>
            </h1>
            
            <div className="slider-container">
                <button className="nav-button prev" onClick={prevSlide}>←</button>
                <div className="slides-wrapper">
                    <div className="slides">
                        {slides.map((slide, index) => (
                            <div 
                                key={slide.id}
                                className={`slide ${index === currentSlide ? 'active' : ''}`}
                                style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}
                            >
                                <img src={slide.image} alt={`Slide ${slide.id}`} />
                            </div>
                        ))}
                    </div>
                </div>
                <button className="nav-button next" onClick={nextSlide}>→</button>
            </div>
        </div>
    );
};

export default Gallery;