import React from 'react';
import ContactForm from './ContactForm';
import '../css/ContactUs.css';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

const ContactUs = () => {
    return (
        <div className="contact-container container">
             <ContactForm />
            <div className="contact-info">
                <h1 className="contact-title text-center">
                    <span className="title-contact">CONTACT</span>
                    <span className="title-us">US</span>
                </h1>

                <div className="contact-details">
                    <div className="contact-row text-center">
                        <div className="corporate-address">
                            <h2>Corporate Address</h2>
                            <p>SKAY Heights, Plot No: 267,</p>
                            <p>Flat No: 501, Narsingi,</p>
                            <p>Gandipet Mandal,</p>
                            <p>Hyderabad-500089</p>

                            <div className="social-links">
                                <a href="https://www.facebook.com/noveohomesadibatla" className="social-icon"><FaFacebookF /></a>
                                <a href="https://www.instagram.com/rochishmati_noveohomes/" className="social-icon"><FaInstagram /></a>
                                <a href="https://www.youtube.com/@rochishmatiinfra-projectsp5674" className="social-icon"><FaYoutube /></a>
                            </div>
                        </div>

                        <div className="contact-column">
                            <div className="call-us">
                                <h2>Call Us</h2>
                               <a href='tel:+919063489988' className='call-us-link' style={{marginRight: '10px', textDecoration: 'none', color: 'white'}}>+91 9063489988</a> <br /> <a href='tel:+919063489989' className='call-us-link' style={{ textDecoration: 'none', color: 'white'}}>+91 9063489989</a>
                            </div>

                            <div className="email-us">
                                <h2>Email Us</h2>
                                <p>sales@rochishmati.in</p>
                                <p>info@rochishmati.in</p>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>

            {/* <ContactForm /> */}
        </div>
    );
};

export default ContactUs;
