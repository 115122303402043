import React, { useState } from 'react';
import '../css/HeroSection.css';
import FormPopup from './FormPopup';
import FormPopupEnquire from './FormPopupEnquire';
import { FaBars, FaTimes } from 'react-icons/fa';

const HeroSection = () => {
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [showFormPopupEnquire, setShowFormPopupEnquire] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDownloadBrochure = () => {
    setShowFormPopup(true);
    setIsMenuOpen(false);
  };

  const handleCloseFormPopup = () => {
    setShowFormPopup(false);
  };

  const handleCloseFormPopupEnquire = () => {
    setShowFormPopupEnquire(false);
  };

  const handleCallUs = () => {
    window.location.href = 'tel:+919063489988'
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEnquireNow = () => {
    setShowFormPopupEnquire(true);
  }

  return (
    <div className="hero-wrapper">
      <nav className={`mobile-nav-links ${isMenuOpen ? 'active' : ''}`}>
        <button className="close-menu" onClick={() => setIsMenuOpen(false)}>
          <FaTimes />
        </button>
        <a href="#home" onClick={() => setIsMenuOpen(false)}>Home</a>
        <a href="#about" onClick={() => setIsMenuOpen(false)}>About</a>
        <a href="#gallery" onClick={() => setIsMenuOpen(false)}>Gallery</a>
        <a href="#amenities" onClick={() => setIsMenuOpen(false)}>Amenities</a>
        <a href='#contact' onClick={() => setIsMenuOpen(false)}>Contact</a>
      </nav>

      <div className="hero-container">
        <header className="hero container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <div className="logo">
                <img src={require('../assets/images/Logo.webp')} alt="Your Logo" />
              </div>
            </div>

            <div className="col-6 col-md-7">
              <div className="desktop-nav-links">
                <a href="#home">Home</a>
                <a href="#about">About</a>
                <a href="#gallery">Gallery</a>
                <a href="#amenities">Amenities</a>
                <a href='#contact'>Contact</a>
              </div>
            </div>

            <div className="col-3 col-md-3 d-flex justify-content-end">
              <div className="nav-right">
                <a
                  href="#"
                  className="download-brochure desktop-download"
                  onClick={handleDownloadBrochure}
                >
                  Download Brochure
                </a>
                <button className="menu-icon" onClick={toggleMenu}>
                  {isMenuOpen ? <FaTimes /> : <FaBars />}
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="hero-text">
          <h1 className="hero-title">
            <span className="hero-title-welcome">Welcome to</span>
            <span className="hero-title-rochishmati"> ROCHISHMATI</span>
          </h1>
          <h3 className="hero-tag">
            Build amazing and professional works with{' '}
            <span>ROCHISHMATI</span>
          </h3>
          <a
            href="#"
            className="download-brochure mobile-hero-download"
            onClick={handleDownloadBrochure}
          >
            Download Brochure
          </a>
        </div>
      </div>

      <div className="stats-container">
        <div className="stat-item">
          <div className="stat-value">2 & 3</div>
          <div className="stat-label">BHK</div>
        </div>
        <div className="stat-item">
          <div className="stat-value">3.5</div>
          <div className="stat-label">Acres</div>
        </div>
        <div className="stat-item">
          <div className="stat-value">900 to 1935</div>
          <div className="stat-label">Sft</div>
        </div>
        <div className="stat-item">
          <div className="stat-value">G+6</div>
          <div className="stat-label">Floors</div>
        </div>
      </div>

      {showFormPopup && (
        <div className="form-popup-container" onClick={handleCloseFormPopup}>
          <FormPopup onClose={handleCloseFormPopup} />
        </div>
      )}

      {showFormPopupEnquire && (
        <div className="form-popup-container" onClick={handleCloseFormPopupEnquire}>
          <FormPopupEnquire onClose={handleCloseFormPopupEnquire} />
        </div>
      )}

      <button className="sticky-button" onClick={handleEnquireNow}>Book a Visit</button>

      <div className="mobile-sticky-buttons">
        <button className="mobile-sticky-button" onClick={handleEnquireNow}>
          Enquire Now
        </button>
        <button className="mobile-sticky-button" onClick={handleCallUs}>
          Call Us
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
