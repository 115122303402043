import React, { useState } from 'react';
import '../css/ContactForm.css';
import { submitLeadForm } from '../services/api';
import { validateForm } from '../utils/validation';

const FormPopup = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        mobileNo: '',
        email: '',
        configuration: ''
    });
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        onClose();
    }

    const downloadBrochure = () => {
        try {
            const link = document.createElement('a');
            link.href = '/brochure/brochure_rochishmathi.pdf';
            link.download = 'Rochishmati-Brochure.pdf';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading brochure:', error);
            setErrors({ submit: 'Failed to download brochure. Please try again.' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setSuccess(false);

        // Validate form
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        
        try {
            await submitLeadForm(formData);
            setSuccess(true);
            
            // Clear form
            setFormData({
                name: '',
                email: '',
                mobileNo: '',
                configuration: ''
            });

            // Download brochure after showing success message
            setTimeout(() => {
                downloadBrochure();
                setTimeout(() => {
                    setSuccess(false);
                    onClose();
                }, 1000);
            }, 1500);

        } catch (error) {
            console.error('Error:', error);
            setErrors({ submit: 'Failed to submit form. Please try again.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    return (
        <div className="contact-form-container" onClick={(e) => e.stopPropagation()}>
            <h2 style={{fontSize: '2rem', fontWeight:'700', color:'#1B332C'}}>
                Access Our Brochure 
                <span 
                    className='close-form-popup' 
                    onClick={handleClose} 
                    style={{
                        fontSize: '1.5rem', 
                        fontWeight:'700', 
                        color:'#1B332C', 
                        cursor:'pointer',  
                        right:'10px'
                    }}
                >
                    X
                </span>
            </h2> 
            <h3 style={{fontSize: '1.5rem', fontWeight:'400', color:'#1B332C'}}>
                Just One Step Away
            </h3>
            
            {success && (
                <div className="success-message">
                    Thank you! Your brochure is ready for download.
                </div>
            )}
            {errors.submit && (
                <div className="error-message">{errors.submit}</div>
            )}
            
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name*"
                        value={formData.name}
                        onChange={handleChange}
                        className={errors.name ? 'error' : ''}
                        required
                    />
                    {errors.name && <span className="error-text">{errors.name}</span>}
                </div>

                <div className="form-group">
                    <input 
                        type="tel"
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={handleChange}
                        placeholder="Mobile Number*"
                        className={errors.mobileNo ? 'error' : ''}
                        required
                    />
                    {errors.mobileNo && <span className="error-text">{errors.mobileNo}</span>}
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Your e-mail*"
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? 'error' : ''}
                        required
                    />
                    {errors.email && <span className="error-text">{errors.email}</span>}
                </div>

                <div className="form-group">
                    <select
                        name="configuration"
                        value={formData.configuration}
                        onChange={handleChange}
                        className={errors.configuration ? 'error' : ''}
                        
                    >
                        <option value="">Select Property Type*</option>
                        <option value="2 BHK">2 BHK</option>
                        <option value="3 BHK">3 BHK</option>
                    </select>
                    {errors.configuration && <span className="error-text">{errors.configuration}</span>}
                </div>

                <button 
                    type="submit" 
                    disabled={isLoading}
                    className={isLoading ? 'loading' : ''}
                >
                    {isLoading ? 'Submitting...' : 'Download Brochure'}
                </button>
            </form>
        </div>
    );
};

export default FormPopup;
