import React, { useState } from 'react';
import '../css/Plans.css';

const Plans = () => {
    const [selectedPlan, setSelectedPlan] = useState('Block A');

    const plans = [
        { id: 'Block A', image: require('../assets/images/floor/Block A.webp') },
        { id: 'Block B', image: require('../assets/images/floor/Block B.webp') },
        { id: 'Block C', image: require('../assets/images/floor/Block C.webp') },
        { id: 'Block D', image: require('../assets/images/floor/Block D.webp') },
        { id: 'Block E', image: require('../assets/images/floor/Block E.webp') },
        
    ];

    const handlePlanClick = (planId) => {
        setSelectedPlan(planId);
    };

    return (
        <div className="plans-container">
            <h1 className="plans-title">
                <span className="title-our">OUR</span>
                <span className="title-plans">FLOOR PLANS</span>
            </h1>
            
            <div className="plan-buttons">
                {plans.map((plan) => (
                    <button
                        key={plan.id}
                        className={`plan-button ${selectedPlan === plan.id ? 'active' : ''}`}
                        onClick={() => handlePlanClick(plan.id)}
                    >
                        {plan.id}
                    </button>
                ))}
            </div>
            
            <div className="plan-image-container">
                <img 
                    src={plans.find(plan => plan.id === selectedPlan)?.image} 
                    alt={`Floor plan for ${selectedPlan}`}
                    className="plan-image"
                />
            </div>
        </div>
    );
};

export default Plans;
