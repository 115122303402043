import React, { useState } from 'react';
import '../css/Location.css';

const Location = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleViewMore = (e) => {
        e.preventDefault();
        setShowMore(!showMore);
    };

    return (
        <div className="location-container">
            <h1 className="location-title">
                <span className="title-our">Explore Our</span>
                <span className="title-location">Site</span>
            </h1>
            
            <div className="location-content">
                <div className="map-container">
                    <iframe
                        title='Noveo Homes Location'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3810.7022314678525!2d78.56268047590824!3d17.23322870722636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba41d5de81655%3A0x2342f43c63124b4c!2sRochishmati%20Noveo%20Center!5e0!3m2!1sen!2sin!4v1731491989920!5m2!1sen!2sin" 
                        width="100%"
                        height="100%"
                        style={{ border: 0, borderRadius: '15px' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
                
                <div className="location-description">
                    <p className="preview-text">
                        Living in Rochishmathi's Noveo Homes, you can simply walk to TCS and most of the proposed global Aerospace companies in a few minutes from your home.
                    </p>
                    <a href='#location-details' className='view-more' onClick={toggleViewMore}>
                        {showMore ? 'view less' : 'view more'}
                    </a>
                    <div className={`full-description ${showMore ? 'show' : ''}`}>
                        <p>
                            Noveo Homes shares a common wall with TCS campus and abuts on proposed 100ft wide main road. A global community on the shores of ORR at Adibatla. An infrastructure of shopping, recreation is taking shape in neighborhood. Schools like DPS and Sloka International are within ten minute drive distance. A global environment, an elite community, pollution-free neighborhood will enrich your life at Noveo Homes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
