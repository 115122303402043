import React from 'react';
import '../css/Amenities.css';

import { FaHouseUser, FaBed, FaDumbbell, FaGamepad, FaWater, 
         FaBiking, FaUmbrellaBeach, FaChild, FaParking, FaShieldAlt, FaBolt, FaPray } from 'react-icons/fa';
import { GiMeditation, GiLotus, GiYinYang } from 'react-icons/gi';
import { IoIosFitness } from 'react-icons/io';

const Amenities = () => {
    const amenitiesData = [
        {
            icon: <FaHouseUser />,
            image: require('../assets/images/amenities/Banquet Hall.webp'),
            name: "Banquet Hall"
        },
        {
            icon: <FaUmbrellaBeach />,
            image: require('../assets/images/amenities/Dinning Hall.webp'),
            name: "Dining Hall"
        },
        {
            icon: <IoIosFitness />,
            image: require('../assets/images/amenities/Gym.webp'),
            name: "Gym"
        },
        {
            icon: <FaGamepad />,
            image: require('../assets/images/amenities/Indoor games 2.webp'),
            name: "Indoor Games"
        },
        {
            icon: <FaShieldAlt />,
            image: require('../assets/images/amenities/Reception.webp'),
            name: "Reception"
        },
        {
            icon: <FaWater />,
            image: require('../assets/images/amenities/Swimming pool.webp'),
            name: "Swimming Pool"
        }
    ];

    return (
        <div className="amenities-container">
            <h1 className="amenities-title">
                <span className="titleo">Our</span>
                <span className="title-amenities">Amenities</span>
            </h1>
            
            <div className="amenities-grid">
                {amenitiesData.map((amenity, index) => (
                    <div className="amenity-item" key={index}>
                        {/* <div className="amenity-icon">{amenity.icon}</div> */}
                        <img 
                            src={amenity.image} 
                            alt={amenity.name} 
                            className="amenity-image"
                        />
                        <div className="amenity-name">{amenity.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Amenities;
